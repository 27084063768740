/*
    Current Page Key
*/
export const currentPageKey = ref(null);

/*
    Set the current page key
*/
export const setCurrentPageKey = (key) => {
    currentPageKey.value = key;
};

/*
    Get the permission for a specific page
    If a client has bought a specific module some pages are available for them
    otherwise a route middleware will create a 404 page
*/
export const getPagePermission = (slug) => {
    const { data: pagePermissionData } = useNuxtData('pagePermissions');
    const foundEntry = pagePermissionData.value?.find(
        (entry) => entry.slugs?.includes(slug),
    ) || null;

    if (foundEntry?.enabled) {
        setCurrentPageKey(foundEntry.slugs[0]);
        return true;
    }

    return false;
};

/*
    Get the data of a specific module
    You can set the data for your modules in /api/modules/index.js
*/
export const getModuleData = (moduleName) => {
    const { locale } = useI18n();
    const { data: modules } = useNuxtData('modules');

    if (!modules.value) {
        throw createError({
            statusCode: 500,
            message: 'No modules were found / @getModuleData',
            fatal: true,
        });
    }

    const currentModule = modules.value[moduleName];

    if (!currentModule) {
        // Retrun empty object if module is not found or not enabled
        return {};
    }

    const moduleContent = currentModule.content[locale.value];
    const mappedAddons = currentModule.addons?.map((addon) => ({
        ...addon,
        content: toRaw(addon.content[locale.value]),
    })) || [];
    const filteredAddons = mappedAddons?.filter((addon) => addon.enabled) || [];

    if (!currentModule.enabled) {
        throw createError({
            statusCode: 403,
            message: `Permission denied for module with name: ${moduleName} / @getModuleData`,
            fatal: true,
        });
    }

    return {
        ...currentModule,
        content: toRaw(moduleContent),
        addons: filteredAddons,
    };
};

/*
    Get all available modules
*/
const getAvailableModulesDefaults = {
    cardsOnly: false, // Only has effect if output is 'array'
    output: 'object',
    language: 'de',
};
export const getAvailableModules = (inputOptions) => {
    const options = { ...getAvailableModulesDefaults, ...inputOptions };

    const { data: modules } = useNuxtData('modules');

    if (!modules.value) {
        throw createError({
            statusCode: 500,
            message: 'No modules were found / @getAvailableModules',
            fatal: true,
        });
    }

    if (options.output === 'object') {
        return toRaw(modules.value);
    }

    if (options.output === 'array') {
        const modulesArray = Object.values(toRaw(modules.value));

        if (options.cardsOnly) {
            return modulesArray.filter((module) => module.hasCard);
        }

        return modulesArray;
    }

    return modules;
};

/*
    Composable to get the content of the current page
    You can add new pages inside /content/ and register them
    api/pages/index.js
*/
export const getPageContent = (page) => {
    const { data } = useNuxtData('pages');

    if (!data.value) {
        throw createError({
            statusCode: 404,
            message: 'No pages were found / @usePageContent',
            fatal: true,
        });
    }

    const { locale } = useI18n();
    const pageKey = page || currentPageKey.value;
    const pageData = data.value[pageKey];

    if (!pageData) {
        throw createError({
            statusCode: 404,
            message: 'Page not found',
        });
    }

    const localizedData = Object.entries(pageData).reduce((acc, [
        key, value,
    ]) => {
        acc[key] = value[locale.value];
        return acc;
    }, {});

    return localizedData;
};

/*
    Get campaign global data
*/
export const getCampaignGlobals = (currentLocale) => {
    const { data } = useNuxtData('globals');

    if (!data.value) {
        throw createError({
            statusCode: 404,
            message: 'No globals data was found / @getCampaignData',
            fatal: true,
        });
    }

    return data.value[currentLocale];
};
